<template>
  <div style="width: 100%;">
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="title" label="名称" width="200"></el-table-column>
      <el-table-column :formatter="item=>item.need_judge ? '启用' : '关闭'" label="活动需审核" width="200"></el-table-column>
      <el-table-column prop="balance" label="余额" width="80"></el-table-column>
      <el-table-column prop="award_rate" label="佣金比例" :formatter="rateFormatter" width="80"></el-table-column>
      <el-table-column prop="manager_user.id" label="用户编号" width="80"></el-table-column>
      <el-table-column prop="manager_user.nickname" label="用户昵称" width="120"></el-table-column>
      <el-table-column label="创建时间">
        <template #default="s">{{ s.row.created_at | date }}</template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="280">
        <template #default="s">
          <div style="margin-bottom: .5rem">
            <el-button @click="balanceChange(s.row)" size="mini" style="width: 14.8rem">余额变动明细</el-button>
          </div>
          <el-button @click="editInfo(s.row)" type="warning" size="mini">编辑信息</el-button>
          <el-button @click="editPassword(s.row)" type="warning" size="mini">修改密码</el-button>
          <el-button @click="del(s.row)" type="danger" size="small">删除</el-button>
        </template>
        <template #header>
          <el-button @click="addFormBox=true" size="mini" type="primary">添加代理</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog title="添加代理" :visible.sync="addFormBox">
      <el-form label-width="100px" style="padding-right: 4rem">
        <el-form-item label="代理名称">
          <el-input v-model="addForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="代理账号">
          <el-input v-model="addForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="代理密码">
          <el-input v-model="addForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="活动审核">
          <el-switch v-model="addForm.need_judge"></el-switch>
        </el-form-item>
        <el-form-item label="分佣比例">
          <div>
            <el-input-number :min="0" :max="100" v-model="addForm.award_rate"></el-input-number>
            <span style="margin-left: 1rem">%</span>
          </div>
          <span class="y-desc" style="line-height: 1rem">订单分佣比例，代理创建活动有新订单时发放 佣金计算方式 订单金额*分佣比例</span>
        </el-form-item>
        <el-form-item label="管理员">
          <y_choose_member v-model="addForm.manager_uid"></y_choose_member>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormBox = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑信息" :visible.sync="editInfoFormBox" @close="editInfoClose">
      <el-form label-width="100px" style="padding-right: 4rem">
        <el-form-item label="代理名称">
          <el-input v-model="editInfoForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="活动审核">
          <el-switch v-model="editInfoForm.need_judge"></el-switch>
        </el-form-item>
        <el-form-item label="分佣比例">
          <div>
            <el-input-number :min="0" :max="100" v-model="editInfoForm.award_rate"></el-input-number>
            <span style="margin-left: 1rem">%</span>
          </div>
          <span class="y-desc" style="line-height: 1rem">订单分佣比例，代理创建活动有新订单时发放 佣金计算方式 订单金额*分佣比例</span>
        </el-form-item>
        <el-form-item label="管理员">
          <y_choose_member v-model="editInfoForm.manager_uid"></y_choose_member>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editInfoFormBox = false">取 消</el-button>
        <el-button type="primary" @click="editInfoSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="editPasswordFormBox" @close="editPasswordClose">
      <el-form label-width="100px" style="padding-right: 4rem">
        <el-form-item label="代理名称">
          <el-input disabled v-model="editPasswordForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="代理账号">
          <el-input v-model="editPasswordForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="代理密码">
          <el-input v-model="editPasswordForm.password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editPasswordFormBox = false">取 消</el-button>
        <el-button type="primary" @click="editPasswordSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_choose_member from "@/components/y_choose_member";

export default {
  name: "list",
  components: {y_choose_member},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      loading: false,
      addFormBox: false,
      addForm: {
        title: "",
        account: "",
        password: "",
        award_rate: 0,
        manager_uid: 0,
        need_judge: false,
      },
      editInfoFormBox: false,
      editInfoForm: {},
      editPasswordFormBox: false,
      editPasswordForm: {
        agent_id: 0,
        title: "",
        account: "",
        password: "",
        need_judge: false,
      }
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    balanceChange(item){
      this.$router.push({
        name:"agentBalanceChange",
        params:this.$route.params,
        query:{
          ...this.$route.query,
          agent_id:item.id
        }
      })
    },
    rateFormatter(item){
      return `${item.award_rate}%`;
    },
    editPassword(item) {
      this.editPasswordForm = {
        agent_id: item.id,
        title: item.title,
        account: item.account,
        password: item.password,
      }
      this.editPasswordFormBox = true;
    },
    editPasswordClose() {
      this.editPasswordForm = {
        agent_id: 0,
        title: "",
        account: "",
        password: "",
      }
      this.editPasswordFormBox = false;
    },
    editPasswordSubmit() {
      this.$api.plugin.agent.editPassword(this.editPasswordForm).then(() => {
        this.$message.success("操作成功");
        this.editPasswordClose();
        this.loadList();
      })
    },
    editInfo(item) {
      this.editInfoForm = {...item}
      this.editInfoFormBox = true;
    },
    editInfoClose() {
      this.editInfoForm = {}
      this.editInfoFormBox = false;
    },
    editInfoSubmit() {
      this.$api.plugin.agent.editInfo(this.editInfoForm).then(() => {
        this.$message.success("操作成功");
        this.editInfoClose();
        this.loadList();
      })
    },
    add() {
      this.$api.plugin.agent.add(this.addForm).then(() => {
        this.$message.success("操作成功");
        this.addFormBox = false;
        this.loadList();
      })
    },
    del(item) {
      this.$api.plugin.agent.del({id: item.id}).then(() => {
        this.$message.success("操作成功");
        this.loadList();
      })
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      this.$api.plugin.agent.list({
        page: this.page,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>